// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/PasswordField.js\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/PasswordField.js");
  import.meta.hot.lastModified = "1714473812635.064";
}
// REMIX HMR END

import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
const PasswordField = ({
  value,
  onChange = null,
  name = "password",
  autoComplete = "new-password"
}) => {
  _s();
  const [show_password, set_show_password] = useState(false);
  const {
    local_value,
    set_local_value
  } = useState(value || "");
  return <InputGroup className="mb-3">
			<Form.Control type={show_password ? "text" : "password"} name={name} value={value || local_value} onChange={e => {
      onChange ? onChange(e.target.value) : set_local_value(e.target.value);
    }} autoComplete={autoComplete} />
			<InputGroup.Text onClick={() => {
      set_show_password(!show_password);
    }}>
				{show_password ? <i className="ri-eye-line"></i> : <i className="ri-eye-off-line"></i>}
			</InputGroup.Text>
		</InputGroup>;
};
_s(PasswordField, "wbUoMosmard9vUHMJLTLmnYYNmQ=");
_c = PasswordField;
export default PasswordField;
var _c;
$RefreshReg$(_c, "PasswordField");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;